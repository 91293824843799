import { render, staticRenderFns } from "./support-portal-layout.vue?vue&type=template&id=6162530c&scoped=true&"
import script from "./support-portal-layout.vue?vue&type=script&lang=js&"
export * from "./support-portal-layout.vue?vue&type=script&lang=js&"
import style0 from "./support-portal-layout.vue?vue&type=style&index=0&id=6162530c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6162530c",
  null
  
)

export default component.exports