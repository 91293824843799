<template>
  <MLayout class="loginLayout">
    <MLayoutContent>
      <slot />
    </MLayoutContent>
  </MLayout>
</template>

<script>
import { BrandingComputed } from '@state/modules/branding'
import { constantsLocale } from '@src/utils/constants-locale'
export default {
  name: 'LoginLayout',
  computed: {
    ...BrandingComputed,
  },
  watch: {
    companyFavicon: {
      immediate: true,
      handler(newValue) {
        let link = document.querySelector("link[rel*='icon']")
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = newValue
        link = null
        // document.getElementsByTagName('head')[0].appendChild(link);
      },
    },
  },
  created() {
    setTimeout(() => {
      if (!window.globallyLocaleLoaded) {
        this.$i18n.mergeLocaleMessage('en', constantsLocale())
      }
    })
  },
}
</script>

<style lang="less" scoped>
@import '~@design';

.loginLayout {
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
</style>
